$fontPath: null;

@if ($env == 'development') {
    $fontPath: '/src/fonts';
} @else {
    $fontPath: '/fonts';
}

@font-face {
    font-family: 'fq-icons';
    font-display: swap;
    src: url('#{$fontPath}/fq-icons.eot');
    src: url('#{$fontPath}/fq-icons.eot#iefix') format('embedded-opentype'),
        url('#{$fontPath}/fq-icons.woff2') format('woff2'), url('#{$fontPath}/fq-icons.woff') format('woff'),
        url('#{$fontPath}/fq-icons.ttf') format('truetype'), url('#{$fontPath}/fq-icons.svg#fq-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fq-icons';
    src: url('../font/fq-icons.svg?21757366#fq-icons') format('svg');
  }
}
*/

.icon {
    &--color-confirm {
        color: rgba(var(--color-confirm__neutral), 1);
    }

    &--color-notification {
        color: rgba(var(--color-notification__neutral), 1);
    }

    &--color-status {
        color: rgba(var(--color-status_neutral), 1);
    }
}

[class^='icon-']:before,
[class*=' icon-']:before {
    font-family: 'fq-icons';
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    width: 1em;
    height: 1em;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 

.icon-action:before { content: '\e800'; } /* '' */
.icon-add-debtor:before { content: '\e801'; } /* '' */
.icon-add-facturing-request:before { content: '\e802'; } /* '' */
.icon-alert:before { content: '\e803'; } /* '' */
.icon-archive:before { content: '\e804'; } /* '' */
.icon-arrow-back:before { content: '\e805'; } /* '' */
.icon-arrow-down:before { content: '\e806'; } /* '' */
.icon-arrow-left:before { content: '\e807'; } /* '' */
.icon-arrow-right:before { content: '\e808'; } /* '' */
.icon-arrow-up:before { content: '\e809'; } /* '' */
.icon-attachment:before { content: '\e80a'; } /* '' */
.icon-calculator:before { content: '\e80b'; } /* '' */
.icon-calendar:before { content: '\e80c'; } /* '' */
.icon-check:before { content: '\e80d'; } /* '' */
.icon-close-circle:before { content: '\e80e'; } /* '' */
.icon-close:before { content: '\e80f'; } /* '' */
.icon-connect:before { content: '\e810'; } /* '' */
.icon-controls:before { content: '\e811'; } /* '' */
.icon-credit-management:before { content: '\e812'; } /* '' */
.icon-dashboard:before { content: '\e813'; } /* '' */
.icon-cog:before { content: '\e814'; } /* '' */
.icon-download:before { content: '\e815'; } /* '' */
.icon-earth:before { content: '\e816'; } /* '' */
.icon-edit:before { content: '\e817'; } /* '' */
.icon-email:before { content: '\e818'; } /* '' */
.icon-facebook:before { content: '\e819'; } /* '' */
.icon-factoring-requests:before { content: '\e81a'; } /* '' */
.icon-filter:before { content: '\e81b'; } /* '' */
.icon-flag:before { content: '\e81c'; } /* '' */
.icon-folder:before { content: '\e81d'; } /* '' */
.icon-legal-procedure:before { content: '\e81e'; } /* '' */
.icon-letter:before { content: '\e81f'; } /* '' */
.icon-linkedin:before { content: '\e820'; } /* '' */
.icon-list:before { content: '\e821'; } /* '' */
.icon-location-pin:before { content: '\e822'; } /* '' */
.icon-lock-open:before { content: '\e823'; } /* '' */
.icon-lock:before { content: '\e824'; } /* '' */
.icon-login:before { content: '\e825'; } /* '' */
.icon-mail:before { content: '\e826'; } /* '' */
.icon-map:before { content: '\e827'; } /* '' */
.icon-menu:before { content: '\e828'; } /* '' */
.icon-multiple-factoring-requests:before { content: '\e829'; } /* '' */
.icon-notice:before { content: '\e82a'; } /* '' */
.icon-phone:before { content: '\e82b'; } /* '' */
.icon-plus:before { content: '\e82c'; } /* '' */
.icon-refresh:before { content: '\e82d'; } /* '' */
.icon-reply:before { content: '\e82e'; } /* '' */
.icon-right-corner:before { content: '\e82f'; } /* '' */
.icon-search:before { content: '\e830'; } /* '' */
.icon-settlements:before { content: '\e831'; } /* '' */
.icon-stop:before { content: '\e832'; } /* '' */
.icon-submit-facturing-request:before { content: '\e833'; } /* '' */
.icon-target:before { content: '\e834'; } /* '' */
.icon-trash:before { content: '\e835'; } /* '' */
.icon-twitter:before { content: '\e836'; } /* '' */
.icon-drag:before { content: '\e837'; } /* '' */
.icon-outdent:before { content: '\e838'; } /* '' */
.icon-indent:before { content: '\e839'; } /* '' */
.icon-clear-formatting:before { content: '\e83a'; } /* '' */
.icon-align-left:before { content: '\e83b'; } /* '' */
.icon-align-right:before { content: '\e83c'; } /* '' */
.icon-align-center:before { content: '\e83d'; } /* '' */
.icon-pin-head:before { content: '\e83e'; } /* '' */
.icon-users:before { content: '\e83f'; } /* '' */
.icon-user:before { content: '\e840'; } /* '' */
.icon-info-circled:before { content: '\e841'; } /* '' */
.icon-tags:before { content: '\e842'; } /* '' */
.icon-eye:before { content: '\e843'; } /* '' */
.icon-list-bullet:before { content: '\f0ca'; } /* '' */
.icon-list-numbered:before { content: '\f0cb'; } /* '' */
.icon-sort-alt-down:before { content: '\f161'; } /* '' */
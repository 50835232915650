@mixin applyDataListModifier($root, $modifier) {
    #{$root}--#{$modifier} {
        #{$root} > * {
            color: var(--statusBullet--#{$modifier}-color);
        }
    }
}

.data-list {
    $root: &;
    display: flex;
    flex-direction: column;

    &__row {
        border-bottom: 1px solid rgba(var(--statusBullet--created-color), 1);
        padding: 0.8em 1.2em;
        @include clearfix();

        &:last-child {
            border-bottom: transparent;
        }

        @include applyDataListModifier($root, created);
        @include applyDataListModifier($root, submitted);
        @include applyDataListModifier($root, approved);
        @include applyDataListModifier($root, paid);
        @include applyDataListModifier($root, rejected);
        @include applyDataListModifier($root, on-hold);
    }
}

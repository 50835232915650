.feedback {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 40em;
    margin: 0 auto;

    & > img {
        margin-bottom: 1em;
        width: 6em;
    }

    p {
        margin-bottom: 1.5em;
    }

    .button {
        margin-bottom: 1em;
    }

    &__options {
        .button {
            margin: 0 0.5em;
        }
    }
}

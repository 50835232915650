.rangeinput {
    $root: &;
    display: grid;
    grid-template: auto / 50% 50%;
    position: relative;
    margin-bottom: 1.25em;

    &--column-view {
        grid-template: auto / auto;

        #{$root}__fields {
            top: -1px;

            .input {
                padding: 0.763em;
            }
        }
    }

    &__label {
        padding: 0.8em 0;
        font: normal 1em var(--font-primary);
        @include bp($screen-md-min) {
            font: normal 1em var(--font-primary);
        }
    }

    .formfield {
        &__input-wrapper {
            width: 100%;
        }
    }

    &__fields {
        display: flex;
        background: $color-white;
        border: var(--input-border);
        border-radius: var(--borderRadius);
        position: relative;

        & > div {
            width: 50%;
            margin: 0;
            &:first-child {
                border-right: 1px solid #e4e4e4;
            }
        }
    }
    .input {
        box-shadow: none;
        border: none;
        border-radius: 0;
        width: 100%;
        background: none;
    }
}
